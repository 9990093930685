<template>
  <div>
    <b-modal
      id="communicationPreview"
      ref="modal"
      :title="$t('page.communication.details.title')"
      size="lg"
      hide-footer
      @hide="handleAbort"
    >
      <b-row class="mb-1">
        <b-col cols="4">
          <strong> {{ $t("subject") }}:</strong> {{ communication.subject }}
        </b-col>
        <b-col v-if="communication.readReceipt" cols="auto">
          <img src="../../assets/conferm.svg" alt="v" style="height: 20px" />
          <strong>
            {{ $t("page.communication.stepThree.readRequest") }}
          </strong>
        </b-col>
      </b-row>
      <div class="pt-3">
        <strong>{{ $t("communication") }}:</strong>
      </div>
      <p v-html="communication.body"></p>
      <b-button
        v-if="communication.status !== 'sent'"
        class="float-right mb-4 text-white"
        size="sm"
        @click="redirectToEditCommunication(communication)"
      >
        {{ $t("page.communication.details.editCommunication") }}
      </b-button>
      <b-button
        v-if="communication.status === 'sent'"
        class="float-right mb-4 text-white"
        size="sm"
        @click="getReceiptsReport(communication)"
      >
        {{ $t("page.communication.details.getCommunication") }}
      </b-button>
      <div class="pb-1 pt-3">
        <strong>{{ $t("page.communication.details.sendingDetail") }}:</strong>
      </div>
      <b-table
        show-empty
        hover
        small
        :items="recipients"
        :fields="fields"
        empty-html="<h6 class='text-center text-italic text-uppercase'> No results </h6>"
      >
        <template #head()="data">
          {{ $t(data.label) }}
        </template>
        <template #cell(app)="data">
          <b-form-checkbox v-model="data.item.app" disabled> </b-form-checkbox>
        </template>
        <template #cell(email)="data">
          <b-form-checkbox v-model="data.item.email" disabled>
          </b-form-checkbox>
        </template>
        <template #cell(pdf)="data">
          <b-form-checkbox v-model="data.item.pdf" disabled> </b-form-checkbox>
        </template>
        <template #cell(read)="data">
          <b-icon
            v-if="data.item.read"
            icon="check-circle-fill"
            variant="success"
            scale="0.85"
          ></b-icon>
        </template>
      </b-table>
      <b-button
        v-if="communication.status === 'pending'"
        class="float-right text-white"
        size="sm"
        @click="sendCommunication(communication)"
      >
        {{ $t("page.communication.details.sendCommunication") }}
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import { userMixin, appMixin } from "../../mixins";

export default {
  props: {
    communication: {
      type: Object
    },
    retrieveDataCallback: {
      type: Function,
      default: () => {}
    }
  },
  mixins: [userMixin, appMixin],
  computed: {
    recipients() {
      var data = [];
      this.communication.recipients.forEach(element => {
        var paper = false;
        if (
          (element.email && this.communication.paperToMailUsers) ||
          (!element.email && !element.app)
        ) {
          paper = true;
        }
        var temp = {
          name: this.$_getUserFullName(element.recipientId?.profile),
          app: element.app,
          email: element.email,
          pdf: paper,
          read: element.status === "read"
        };
        data.push(temp);
      });
      return data;
    },
    fields() {
      const initFields = [
        { key: "name", label: "name" },
        { key: "app", label: "app" },
        { key: "email", label: "email" },
        // { key: "pdf", label: "paper" },
        { key: "read", label: "read" }
      ];
      if (!this.$is1Board)
        initFields.splice(3, 0, { key: "pdf", label: "paper" });
      return initFields;
    }
  },
  methods: {
    redirectToEditCommunication(communication) {
      if (communication.condominiumId) this.editCommunication(communication);
      else this.editGroupCommunication(communication);
    },
    async editCommunication(communication) {
      await this.$store.dispatch(
        "condominium/retrieveCondominium",
        communication.condominiumId._id
      );
      this.$router.push({
        name: "EditCommunication",
        params: {
          condId: communication.condominiumId._id,
          comId: communication._id
        }
      });
    },
    async editGroupCommunication(communication) {
      this.$router.push({
        name: "EditGroupCommunication",
        params: {
          communicationId: communication._id
        }
      });
    },
    async sendCommunication(item) {
      try {
        this.$store.commit("loader/SET_LOADER", {});
        // communication based on condominium (old)
        if (item.condominiumId)
          await this.$store.dispatch("communication/sendCommunication", {
            condominiumId: item.condominiumId._id,
            communicationId: item._id
          });
        // communication without condominium dependency (new)
        else
          await this.$store.dispatch("communication/sendCommunicationPure", {
            communicationId: item._id
          });

        await this.retrieveDataCallback();
        this.$emit("close");
      } catch {
      } finally {
        this.$store.commit("loader/RESET");
      }
    },
    async getReceiptsReport() {
      try {
        this.$store.commit("loader/SET_LOADER", {});
        // communication based on condominium (old)
        if (this.communication.condominiumId)
          await this.$store.dispatch("communication/receiptsReport", {
            condominiumId: this.communication.condominiumId._id,
            communicationId: this.communication._id
          });
        // communication without condominium dependency (new)
        else
          await this.$store.dispatch("communication/receiptsReportPure", {
            communicationId: this.communication._id
          });
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    },
    handleAbort() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss" scoped></style>

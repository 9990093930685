<template>
  <div>
    <b-table
      :fields="fields"
      :items="communications"
      :sort-by.sync="_sortBy"
      :sort-desc.sync="_sortDesc"
      responsive="sm"
      outlined
      hover
      show-empty
      small
    >
      <template #empty>
        <h6 class="text-center text-italic text-uppercase">
          {{ $t("page.condominium.emptyList") }}
        </h6>
      </template>
      <template #head()="data">
        <div
          :class="{ clickable: data.field.sort }"
          @click="data.field.sort && setSort(data.column)"
        >
          {{ $t(data.label) }}
          <svg
            v-if="data.column === _sortBy"
            viewBox="0 0 16 16"
            class="sort-icon"
          >
            <path fill-rule="evenodd" :d="sortIcon"></path>
          </svg>
        </div>
      </template>
      <template #cell(subject)="data">
        <div style="max-width: 300px;">
          {{ data.value }}
        </div>
      </template>
      <template #cell(createdAt)="data">
        <span class="text-capitalize">
          {{ data.value | date("DD MMMM YYYY, HH:mm") }}
        </span>
      </template>
      <template #cell(body)="data">
        <div style="max-width: 500px;" class="d-flex">
          <img
            class="col-auto"
            v-if="data.item.readReceipt"
            src="../../assets/conferm.svg"
            alt="v"
            style="width: 15px;
            align-self: flex-start;
            padding: 5px 0;"
          />
          <communication-body :value="data.value" />
        </div>
      </template>
      <template #cell(readcounter)="data">
        {{ recipientsToNotify(data.item) }}
        [{{ getReadCounter(data.item) }}]
        <b-icon
          v-if="
            recipientsToNotify(data.item) === getReadCounter(data.item) &&
              recipientsToNotify(data.item) > 0
          "
          icon="check-circle-fill"
          scale="0.85"
          variant="success"
        ></b-icon>
      </template>
      <template #cell(documents)="data">
        {{ getPaper(data.item) }}
      </template>
      <template #cell(attachments)="data">
        <b-row class="no-gutters">
          <b-col
            v-for="document in data.item.documents"
            :key="document._id"
            class="col-auto"
          >
            <b-button
              v-if="document"
              size="sm"
              class="mr-1"
              @click="readFile(document.url)"
            >
              <b-icon icon="file-earmark-text" />
            </b-button>
          </b-col>
        </b-row>
      </template>
      <template #cell(download)="data">
        <b-button
          v-if="data.item.status === 'sent' && getPaper(data.item) > 0"
          size="sm"
          class="mr-1"
          @click="getPrintPaper(data.item)"
        >
          <b-icon icon="printer" />
        </b-button>
      </template>
      <template #cell(details)="data">
        <b-button
          size="sm"
          class="mr-2"
          @click="openPreview(data.item)"
          v-b-modal.communicationPreview
        >
          <b-icon icon="search"></b-icon>
        </b-button>
      </template>
      <template #cell(status)="data">
        <b-badge
          class="text-white font-weight-normal"
          :variant="statusVariant[data.item.status]"
        >
          {{ $t(`page.communication.status.${data.item.status}`) }}
        </b-badge>
      </template>
    </b-table>

    <preview-communication
      v-if="preview"
      :communication="currentCommunication"
      :retrieveDataCallback="retrieveDataCallback"
      @close="closePreview"
    />
  </div>
</template>

<script>
import PreviewCommunication from "./../Communication/PreviewCommunication";
import CommunicationBody from "./../Communication/CommunicationBody";
export default {
  components: {
    PreviewCommunication,
    CommunicationBody
  },
  props: {
    communications: {
      type: Array,
      default: () => []
    },
    fields: {
      type: Array,
      default: () => []
    },
    sortBy: {
      type: String,
      default: ""
    },
    sortDesc: {
      type: Boolean,
      default: false
    },
    retrieveDataCallback: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      preview: false,
      currentCommunication: {},
      statusVariant: { sent: "green", pending: "cyan" }
    };
  },
  computed: {
    _sortBy: {
      get() {
        return this.sortBy;
      },
      set(value) {
        this.$emit("update:sortBy", value);
      }
    },
    _sortDesc: {
      get() {
        return this.sortDesc;
      },
      set(value) {
        this.$emit("update:sortDesc", value);
      }
    },
    sortIcon() {
      return this._sortDesc
        ? "M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z"
        : "M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z";
    }
  },
  methods: {
    setSort(newSortBy) {
      if (this._sortBy !== newSortBy) {
        this._sortBy = newSortBy;
        this._sortDesc = false;
      } else {
        this._sortDesc = !this._sortDesc;
      }
    },
    readFile(file) {
      window.open(file, "_blank");
    },
    async getPrintPaper(communication) {
      this.$store.commit("loader/SET_LOADER", {});
      try {
        await this.$store.dispatch("communication/paperCommunication", {
          condominiumId: communication.condominiumId._id,
          communicationId: communication._id
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    },
    getPaper(communication) {
      if (!communication.paperToMailUsers) {
        var papers =
          communication.recipients.length -
          (communication.counters.mailSent + communication.counters.appSent);
      } else if (
        communication.emailToAppUsers &&
        communication.paperToMailUsers
      ) {
        papers = communication.recipients.length;
      } else {
        papers =
          communication.recipients.length - communication.counters.appSent;
      }
      if (papers >= 0) {
        return papers;
      }
      return 0;
    },
    getReadCounter(data) {
      return data.counters.appRead + data.counters.mailRead;
    },
    recipientsToNotify(data) {
      if (!data.emailToAppUsers) {
        return data.counters.appSent + data.counters.mailSent;
      } else {
        var onlyMail = data.counters.mailSent - data.counters.appSent;
        return data.counters.appSent + onlyMail;
      }
    },
    openPreview(item) {
      this.currentCommunication = item;
      this.preview = true;
    },
    closePreview() {
      this.currentCommunication = null;
      this.preview = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.sort-icon {
  width: 20px;
}
.body-container {
  /deep/ p {
    margin: 0;
  }
}
.status-container {
  margin-top: 0.3em;
  color: var(--red);
  border: 1px solid var(--red);
  text-align: center;
  border-radius: 5px;
  font-size: 0.9em;
}
.read {
  color: var(--green);
  border: 1px solid var(--green);
}
.pending {
  color: var(--orange);
  border: 1px solid var(--orange);
}
</style>
